/**
 * If you are not familiar with React Navigation, check out the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import LinkingConfiguration from './LinkingConfiguration';
import { RootStackParamList } from 'types/navigation/navigation';
import BottomTabNavigator from './BottomTabNavigator';

const config = {
  screens: {
    Root: {
      screens: {
        Home: {
          screens: {
            SplashScreen: {
              path: 'splash-screen/:venueCode?/:commerceCode?/',
            },
          },
        }
      }
    },
  },
};

const linking = {
  prefixes: ['/'],
  config
};

export default function Navigation(): JSX.Element {
  return (
    <NavigationContainer linking={linking}>
      <RootNavigator />
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Root" component={BottomTabNavigator} />
    </Stack.Navigator>
  );
}
