import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import { styles } from 'styles/components/loading';

export default (): JSX.Element => (
  <View style={styles.container}>
    <View style={styles.indicator}>
      <ActivityIndicator size="large" color="#5b9bf5" />
    </View>
  </View>
);
