import React, { useState } from "react";
import {
  View,
  TextInput,
  Image,
  Text,
  Keyboard,
  TouchableOpacity,
  Linking,
  SafeAreaView,
  ImageBackground,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { CountryPicker, countryCodes } from "react-native-country-codes-picker";
import DropDownPicker from "react-native-dropdown-picker";
import { useFormik } from "formik";
import { Button, CheckBox } from "react-native-elements";
import { Video, ResizeMode } from "expo-av";
import SplashScreenProps from "types/screens/SplashScreenProps";
import { styles } from "styles/screens/splashScreen";
import AppContext from "contexts/AppContext";
import { checkStatus, logConnection, setUserData } from "services/authService";
import { AgeRangeConfig, Splash } from "types/models/Splash";
import Loading from "components/Loading";
import { CreateForm } from "forms/schemas/splashForm";
import { ScrollView } from "react-native-gesture-handler";
import Venue from "types/models/Venue";
import User from "types/models/User";
import JugaBetWidget from "components/JugaBetWidget";

declare global {
  interface Window {
    oauthCallback({
      email,
      phone,
    }: {
      email: string | null;
      phone: string | null;
    }): void;
    formSubmission(): void;
    getPermissionStatus(): string | null;
  }
}

const ExternalLink = ({
  url,
  children,
  underline = false,
}: {
  url: string;
  children: string;
  underline?: boolean;
}): JSX.Element => {
  const onPress = () =>
    Linking.canOpenURL(url).then(() => {
      Linking.openURL(url);
    });

  return (
    <TouchableOpacity onPress={onPress}>
      <Text
        style={[
          styles.termsAndConditionsLink,
          { textDecorationLine: underline ? "underline" : "none" },
        ]}
      >
        {children}
      </Text>
    </TouchableOpacity>
  );
};

export default ({ navigation, route }: SplashScreenProps): JSX.Element => {
  const [isVideoEnded, setIsVideoEnded] = useState<boolean>(false);
  const checkEndVideo = (status: any) => {
    if (status.didJustFinish) {
      setIsVideoEnded(true);
    }
    if (!status) {
      setIsVideoEnded(true);
    }
  };
  return isVideoEnded ? (
    <SplashScreen navigation={navigation} route={route} />
  ) : (
    <PromotionVideoScreen onEnded={checkEndVideo} route={route} />
  );
};
const PromotionVideoScreen = ({ onEnded, route }: any) => {
  const [isMuted, setIsMuted] = useState(true);
  const [promotionVideoURL, setPromotionVideoURL] = React.useState<
    string | null
  >(null);
  const video = React.useRef<any>(null);

  React.useEffect(() => {
    if (!route.params) return;
    if (
      ![route.params.venue, route.params.mac, route.params.node_mac].some(
        Boolean
      )
    )
      return;

    const body = {
      baseGrantUrl: route.params.base_grant_url,
      venueCode: route.params.venue,
      ueMac: route.params.client_mac,
      ueIp: [route.params.uip, route.params.client_ip].find(Boolean),
      macAddress: [route.params.mac, route.params.node_mac].find(Boolean),
    };

    checkConnection(body);
  }, [route.params]);

  const checkConnection = async (body: any): Promise<void> => {
    const response = await checkStatus(body);
    if (!response) return;

    if (response.venue.promotionVideo) {
      setPromotionVideoURL(response.venue.promotionVideo);
    } else if (!response.venue.promotionVideo) {
      setPromotionVideoURL(null);
      onEnded(false);
      return;
    }
  };
  return promotionVideoURL ? (
    <View style={styles.videoContainer}>
      <TouchableOpacity
        activeOpacity={1}
        style={[styles.videoContainer]}
        onPress={() => {
          setIsMuted(false);
        }}
      >
        <Video
          ref={video}
          onError={(error) => {
            console.log("Ocurrio un error al reproducir el video", error);
            onEnded(false);
          }}
          source={{ uri: promotionVideoURL }}
          style={styles.videoContainer}
          videoStyle={styles.video}
          shouldPlay={true}
          isLooping={false}
          isMuted={isMuted}
          onPlaybackStatusUpdate={onEnded}
          resizeMode={ResizeMode.COVER}
        />
      </TouchableOpacity>
    </View>
  ) : null;
};

const SplashScreen = ({ route }: SplashScreenProps) => {
  interface OptionItem {
    value: string;
    label: string;
  }
  const { isLoading, setIsLoading } = React.useContext(AppContext);
  const [actionCode, setActionCode] = React.useState<string | null>(null);
  const [user, setUser] = React.useState<User | null>(null);
  const [venue, setVenue] = React.useState<Venue | null>(null);
  const [splash, setSplash] = React.useState<Splash | null>(null);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [items, setItems] = React.useState<OptionItem[]>([]);
  const [showCountryPicker, setShowCountryPicker] = React.useState(false);
  const [countryPhoneCode, setCountryPhoneCode] = React.useState("");
  const [countryCode, setCountryCode] = React.useState<string | undefined>();
  ("");
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [termAndConditionsConfirmed, setTermAndConditionsConfirmed] =
    React.useState<boolean>(false);

  const numberRegex = /^[0-9]+$/;
  const venueHidesFrame = venue?.hideFrame ?? false;

  interface UserData {
    fullname: string;
    email: string;
    ageRange: string;
    identificationNumber: string;
    phone: string;
  }

  React.useEffect(() => {
    if (!route.params) return;
    if (
      ![route.params.venue, route.params.mac, route.params.node_mac].some(
        Boolean
      )
    )
      return;

    const body = {
      baseGrantUrl: route.params.base_grant_url,
      venueCode: route.params.venue,
      ueMac: route.params.client_mac,
      ueIp: [route.params.uip, route.params.client_ip].find(Boolean),
      macAddress: [route.params.mac, route.params.node_mac].find(Boolean),
    };

    checkConnection(body);
  }, [route.params]);

  const checkConnection = async (body: any): Promise<void> => {
    setIsLoading(true);
    const response = await checkStatus(body);

    if (!response) {
      setIsLoading(false);
      return;
    }

    if (response.actionCode === "01" && response.venue?.splash?.urlToWeb) {
      setIsLoading(false);
      location.href = response.venue?.splash?.urlToWeb;
      return;
    }

    setActionCode(response.actionCode);

    if (response.venue.splash) {
      setVenue(response.venue);

      if (!response.venue.termsAndConditionsLink) {
        setTermAndConditionsConfirmed(true)
      }

      if (response.user) {
        setUser(response.user);
      }
      if (response.venue.countryPhoneCode) {
        setCountryPhoneCode(response.venue.countryPhoneCode);
        const countryCode = countryCodes.find(
          (country) => country.dial_code == response.venue.countryPhoneCode
        )?.code;
        setCountryCode(countryCode);
      }
      setSplash(response.venue.splash);

      if (response.actionCode === "01") {
        setIsLoading(false);
        location.href = response.venue.splash.urlToWeb;
      }
    }

    if (response?.venue.splash?.urlToLogin) {
      setSplash(response.venue.splash);
      if (response.venue.splash.ageRangeConfig.length > 0) {
        parseAgeRangeConfigOptions(response.venue.splash.ageRangeConfig);
      }
    }

    setIsLoading(false);
  };

  const parseAgeRangeConfigOptions = (configs: AgeRangeConfig[]): void => {
    const options = configs.map(
      (config): OptionItem => ({
        value: `${config.fromAge} a ${config.toAge} años`,
        label: `${config.fromAge} a ${config.toAge} años`,
      })
    );
    setItems(options);
  };

  const onConfirm = async (): Promise<void> => {
    const body = {
      ...formik.values,
      countryCode: countryCode ?? null,
      venueId: splash?.venueId,
      baseGrantUrl: route.params.base_grant_url,
      macAddress: [route.params.mac, route.params.node_mac].find(Boolean),
      ueMac: route.params.client_mac,
      ueIp: [route.params.uip, route.params.client_ip].find(Boolean),
      splashId: splash?.id,
      isForRecconect: actionCode === "02" ? false : true,
    };
    setIsLoading(true);
    try {
      const response = await setUserData(body);

      if (response && splash) {
        try {
          const notificationsStatus = await window.getPermissionStatus();

          if (notificationsStatus !== "granted") {
            await window.formSubmission();
          }

          const notificationsStatusRenew = await window.getPermissionStatus();

          if (
            venue?.factorealContactListName &&
            notificationsStatusRenew === "granted"
          ) {
            const data = {
              email: user !== null ? user.email : formik.values.email,
              phone:
                user !== null ? user.phone : countryPhoneCode + phoneNumber,
            };

            await window.oauthCallback?.(data);
          }

          if (route.params.base_grant_url) {
            const url = new URL(route.params.base_grant_url);
            const fullUrl = `${url.protocol}//${url.hostname}${url.pathname}`;
            const params = new URLSearchParams(url.search);

            params.append("continue_url", "https://www.google.com");

            window.open(`${fullUrl}?${params.toString()}`);
          }
        } catch (error) {
          setIsLoading(false);
          console.log("error", error);
        } finally {
          setTimeout(() => {
            setIsLoading(false);
            location.href = response;
          }, 1000);
        }
      }
    } catch (error) {
      console.log("error");
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik<UserData>({
    initialValues: {
      fullname: "",
      email: "",
      ageRange: "",
      identificationNumber: "",
      phone: "",
    },
    onSubmit: () => {
      onConfirm();
    },
    validationSchema: actionCode === "02" ? CreateForm(splash) : null,
    validateOnBlur: false,
    validateOnChange: false,
  });

  React.useEffect(() => {
    if (value) {
      formik.setFieldValue("ageRange", value);
    }
  }, [value]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {
        !venueHidesFrame && splash?.backgroundHeaderImage 
        ? (
          <View>
            <Image
              source={{ uri: splash.backgroundHeaderImage }}
              style={styles.backgroundHeaderImage}
            />
          </View>
        )
        : ""
      }
      {
        !venueHidesFrame && splash?.headerColor
        ? (
          <View
            style={[styles.header, { backgroundColor: splash?.headerColor }]}
          />
        )
        : ""
      }

      <ImageBackground
        resizeMode="cover"
        style={[styles.backgroundMainImage]}
        source={{
          uri: splash?.backgroundMainImage,
        }}
      >

        <ScrollView
          scrollEnabled
          nestedScrollEnabled
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={{ flexGrow: 1, justifyContent: "space-between" }}
          style={[styles.body, { backgroundColor: splash?.backgroundColor }]}
        >
          <View style={{ height: "auto", maxHeight: 0 }}>
            {splash?.promotionBannerImagePath && (
              <View style={styles.imagesContainer}>
                <Image
                  source={{ uri: splash.promotionBannerImagePath }}
                  style={styles.bannerItem}
                />
              </View>
            )}

            <Text
              style={[styles.venueName, { color: splash?.titleColor ?? "#000" }]}
            >
              {venue?.name}
            </Text>
            {actionCode === "02" ? (
              <View style={{ alignItems: "center" }}>
                {splash?.showAgeRange ? (
                  <>
                    <DropDownPicker
                      placeholder="Seleccione su rango de edad"
                      open={open}
                      value={formik.values.ageRange}
                      items={items}
                      setOpen={setOpen}
                      setValue={setValue}
                      setItems={setItems}
                      style={styles.dropdownPicker}
                    />
                    {formik.errors.ageRange &&
                      formik.errors.ageRange.length > 0 && (
                        <Text style={styles.errorText}>
                          {formik.errors.ageRange}
                        </Text>
                      )}
                  </>
                ) : null}
                {splash?.showEmail ? (
                  <>
                    <TextInput
                      keyboardType="email-address"
                      style={[styles.textInput]}
                      onChangeText={formik.handleChange("email")}
                      placeholder={"Correo electrónico"}
                      value={formik.values.email}
                    ></TextInput>
                    {formik.errors.email && formik.errors.email.length > 0 && (
                      <Text style={styles.errorText}>{formik.errors.email}</Text>
                    )}
                  </>
                ) : null}
                {splash?.showPhone ? (
                  <View style={{ width: "90%", marginBottom: 10 }}>
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        onPress={() => setShowCountryPicker(true)}
                        style={[
                          {
                            borderRadius: 20,
                            width: "22%",
                            height: 45,
                            backgroundColor: "#fff",
                            marginTop: 10,
                            borderColor: "#c9c9c9",
                            borderWidth: 1,
                            display: "flex",
                            justifyContent: "center",
                          },
                        ]}
                      >
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignSelf: "center",
                          }}
                        >
                          {countryPhoneCode ? (
                            <Text
                              style={{
                                fontSize: 16,
                              }}
                            >
                              <AntDesign name="down" size={14} color="black" />
                              {countryPhoneCode}
                            </Text>
                          ) : (
                            <AntDesign name="down" size={14} color="black" />
                          )}
                        </View>
                      </TouchableOpacity>
                      <TextInput
                        keyboardType="number-pad"
                        value={formik.values.phone}
                        onChangeText={formik.handleChange("phone")}
                        style={[
                          styles.textInput,
                          {
                            marginTop: 10,
                          },
                        ]}
                        placeholder={"Número de teléfono"}
                        placeholderTextColor="#C4C4C4"
                      />
                    </View>
                    <CountryPicker
                      show={showCountryPicker}
                      lang={"es"}
                      inputPlaceholder={"Buscá tu país"}
                      pickerButtonOnPress={(item) => {
                        setCountryPhoneCode(item.dial_code);
                        setCountryCode(item.code);
                        setShowCountryPicker(false);
                      }}
                      style={{
                        modal: {
                          height: "80%",
                        },
                      }}
                      onBackdropPress={() => setShowCountryPicker(false)}
                    />
                    {formik.values.phone !== "" &&
                      !numberRegex.test(formik.values.phone) && (
                        <View>
                          <Text
                            style={{
                              color: "#F00",
                              fontSize: 16,
                              marginTop: 5,
                            }}
                          >{`El número de teléfono solo puede contener números enteros`}</Text>
                        </View>
                      )}
                    {!countryPhoneCode && phoneNumber !== "" && (
                      <View>
                        <Text
                          style={{
                            color: "#F00",
                            fontSize: 16,
                            marginTop: 5,
                          }}
                        >{`El código de país es requerido`}</Text>
                      </View>
                    )}
                    {formik.errors.phone && formik.errors.phone.length > 0 && (
                      <Text style={styles.errorText}>{formik.errors.phone}</Text>
                    )}
                  </View>
                ) : null}
                {splash?.showFullname ? (
                  <>
                    <TextInput
                      keyboardType="default"
                      style={[styles.textInput]}
                      onChangeText={formik.handleChange("fullname")}
                      placeholder="Nombre y apellido"
                      value={formik.values.fullname}
                    ></TextInput>
                    {formik.errors.fullname &&
                      formik.errors.fullname.length > 0 && (
                        <Text style={styles.errorText}>
                          {formik.errors.fullname}
                        </Text>
                      )}
                  </>
                ) : null}
                {splash?.showIdentificationNumber ? (
                  <>
                    <TextInput
                      keyboardType="default"
                      style={[styles.textInput]}
                      onChangeText={formik.handleChange("identificationNumber")}
                      placeholder={
                        splash.identificationNumberLabel ?? "N° de identificación"
                      }
                      value={formik.values.identificationNumber}
                    ></TextInput>
                    {formik.errors.identificationNumber &&
                      formik.errors.identificationNumber.length > 0 && (
                        <Text style={styles.errorText}>
                          {formik.errors.identificationNumber}
                        </Text>
                      )}
                  </>
                ) : null}
              </View>
            ) : null}

            {actionCode !== "01" && actionCode !== null ? (
              <Button
                title="Aceptar y conectar"
                type="clear"
                containerStyle={styles.confirmPaymentBtnContainer}
                buttonStyle={[
                  styles.confirmPaymentBtn,
                  { backgroundColor: splash?.buttonColor ?? "#2e44e1" },
                ]}
                titleStyle={[
                  styles.confirmPaymentBtnText,
                  { color: splash?.buttonTextColor ?? "#FFF" },
                ]}
                disabled={formik.isSubmitting || !termAndConditionsConfirmed}
                disabledStyle={[
                  styles.confirmPaymentBtn,
                  styles.confirmPaymentBtnDisabled,
                ]}
                disabledTitleStyle={styles.confirmPaymentBtnText}
                onPress={async (): Promise<void> => {
                  Keyboard.dismiss();
                  const notificationsStatus = await window.getPermissionStatus();
                  if (notificationsStatus !== "granted") {
                    await window.formSubmission();
                  }
                  formik.handleSubmit();
                }}
              />
            ) : null}

            {Boolean(splash?.betWidgetUrl) && (
              <JugaBetWidget url={(splash as Splash).betWidgetUrl} />
            )}

            <View style={styles.termsAndConditionsContainer}>
              {venue?.termsAndConditionsLink && (
                <View style={styles.termsAndConditionsWrapper}>
                  <CheckBox
                    iconType="material-community"
                    checkedIcon="checkbox-marked"
                    uncheckedIcon="checkbox-blank-outline"
                    checked={termAndConditionsConfirmed}
                    checkedColor={splash?.titleColor ?? "#000000"}
                    onPress={() => setTermAndConditionsConfirmed((b) => !b)}
                  />

                  <View style={styles.externalLinkWrapper}>
                    <Text>He leído los</Text>
                    <ExternalLink underline url={venue.termsAndConditionsLink}>
                      términos y condiciones
                    </ExternalLink>
                  </View>
                </View>
              )}
              {venue?.politicsUrl && (
                <ExternalLink url={venue.politicsUrl}>
                  Políticas de Privacidad
                </ExternalLink>
              )}
            </View>
          </View>
        </ScrollView>
      </ImageBackground>
      {
        !venueHidesFrame && splash?.backgroundFooterImage 
        ? (
          <View>
            <Image
              source={{ uri: splash.backgroundFooterImage }}
              style={styles.backgroundFooterImage}
            />
          </View>

        )
        : ""
      }
      {
        !venueHidesFrame && splash?.footerColor
        ? (
          <View
            style={[styles.footer, { backgroundColor: splash?.footerColor }]}
          />
        )
        : ""
      }
    </SafeAreaView>
  );
};
