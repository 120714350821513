import Venue from 'types/models/Venue';
import { AgeRangeConfig, Splash } from 'types/models/Splash';
//import AuthData from 'types/models/AuthData';
//import User from 'types/models/User';

export const parseVenue = (venue: any): Venue => {
  return {
    id: venue.id,
    name: venue.name,
    code: venue.code,
    termsAndConditionsLink: venue.terms_and_conditions_link,
    logoSubtitle: venue.logo_subtitle,
    hasDiferentLogoLayout: venue.has_diferent_logo_layout === 1,
    hideFrame: venue.hide_frame === 1,
    politicsUrl: venue.politics_url,
    promotionVideo: venue.promotion_video,
    factorealContactListName: venue.factoreal_contact_list_name,
    countryPhoneCode: venue.country_code,
    splash: venue.splash ? parseSplash(venue.splash) : undefined
  };
};

const parseSplash = (splash: any): Splash => {
  return {
    id: splash.id,
    title: splash.title,
    titleColor: splash.title_color,
    headerColor: splash.header_color,
    backgroundColor: splash.background_color,
    footerColor: splash.footer_color,
    buttonColor: splash.button_color,
    buttonTextColor: splash.button_text_color,
    venueId: splash.venue_id,
    active: splash.active === 1,
    logoPath: splash.logo_path,
    urlToLogin: splash.url_to_login,
    urlToWeb: splash.url_to_web,
    conectionTime: splash.conection_time,
    showEmail: splash.show_email === 1,
    showPhone: splash.show_phone === 1,
    showFullname: splash.show_fullname === 1,
    showIdentificationNumber: splash.show_nacionality === 1,
    betWidgetUrl: splash.bet_widget_url,
    showAgeRange: splash.show_age_range === 1,
    emailIsRequired: splash.email_is_required === 1,
    phoneIsRequired: splash.phone_is_required === 1,
    fullnameIsRequired: splash.fullname_is_required === 1,
    identificationNumberLabel: splash.identification_number_label,
    identificationNumberIsRequired: splash.identification_number_is_required === 1,
    ageRangeIsRequired: splash.age_range_is_required === 1,
    ageRangeConfig: splash.age_range_configs ? parseAgeRangeConfigs(splash.age_range_configs) : [],
    promotionImagePath: splash.promotion_image_path ?? undefined,
    promotionBannerImagePath: splash.promotion_banner_image_path ?? undefined,
    backgroundMainImage: splash.background_main_image_path ?? undefined,
    backgroundHeaderImage: splash.background_header_image_path ?? undefined,
    backgroundFooterImage: splash.background_footer_image_path ?? undefined
  };
};

const parseAgeRangeConfigs = (configs: any): AgeRangeConfig[] => {
  return configs.map((config: any) => ({
    id: config.id,
    fromAge: config.from_age,
    toAge: config.to_age
  }));
};
